import React, { useState, useEffect } from "react";
import Buttons from "./Buttons";
import RadioButtons from "./RadioButtons";
import Checkbox from "./Checkbox";
import Overlay from "./Overlay";
import { useNavigate } from "react-router-dom";

import "../../css/practice/Accordion.css";

function RandomPopup() {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    // Show the overlay when the component first mounts
    setShowOverlay(true);
  }, []);

  const handleToggle1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleToggle2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleToggle3 = () => {
    setIsOpen3(!isOpen3);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        back to practice
      </a>
      <button
        className='practice-button'
        onClick={handleToggle1}
        data-testid='accordion-1'
      >
        Accordion 1
      </button>
      {isOpen1 && <Buttons />}
      <button
        className='practice-button'
        onClick={handleToggle2}
        data-testid='accordion-2'
      >
        Accordion 2
      </button>
      {isOpen2 && <RadioButtons />}
      <button
        className='practice-button'
        onClick={handleToggle3}
        data-testid='accordion-3'
      >
        Accordion 3
      </button>
      {isOpen3 && <Checkbox />}
      {showOverlay && <Overlay onClose={handleOverlayClose} />}
    </div>
  );
}

export default RandomPopup;
