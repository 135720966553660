import React, { useState } from "react";
import "../css/Practice.css";
import { useNavigate } from "react-router-dom";

function Practice() {
  const navigate = useNavigate();

  const handleIframeClick = () => {
    navigate("/practice-iframe");
  };
  const handleApiClick = () => {
    navigate("/practice-api");
  };
  const handleDynamicTextClick = () => {
    navigate("/practice-dyanmic-text");
  };
  const handleFileUploadClick = () => {
    navigate("/practice-file-upload");
  };
  const handleDragAndDropClick = () => {
    navigate("/practice-drag-and-drop");
  };
  const handleContactFormClick = () => {
    navigate("/practice-contact-form");
  };
  const handleAccordionsClick = () => {
    navigate("/practice-accordions");
  };
  const handleGeneralComponentsClick = () => {
    navigate("/practice-general-components");
  };
  const handleMockDataLayerClick = () => {
    navigate("/practice-mock-data-layer");
  };
  const handlePopUpClick = () => {
    navigate("/practice-random-popup");
  };
  const handleFileDownloadClick = () => {
    navigate("/practice-file-download");
  };
  const handleClockClick = () => {
    navigate("/practice-clock");
  };

  return (
    <div>
      <p>
        {" "}
        Note to user: This page is likely to be updated - Things may move around
        and extra items will be added.
      </p>
      {/* General input components*/}

      <div
        className='general-components-container container-outline'
        onClick={handleGeneralComponentsClick}
        data-testid='practice-general'
      >
        <h2 className='container-text'>General Components</h2>
        <p className='container-text'>Click here to practice working with:</p>
        <ul className='container-text extra-info'>
          <li className='extra-info'> - Buttons </li>
          <li> - Radio Buttons </li>
          <li> - Dropdowns </li>
          <li> - Checkboxes </li>
          <li> - Links </li>
        </ul>
      </div>

      {/* Accordions*/}
      <div
        className='accordions-container container-outline'
        onClick={handleAccordionsClick}
        data-testid='practice-accordions'
      >
        <h2 className='container-text'>Accordions</h2>
        <p className='container-text'>
          Click here to practice working with accordions
        </p>
      </div>
      {/*handlePopUpClick*/}
      <div
        className='popup-container container-outline'
        onClick={handlePopUpClick}
        data-testid='practice-random-overlay'
      >
        <h2 className='container-text'>Popups</h2>
        <p className='container-text'>
          Click here to practice working with random popups
        </p>
      </div>

      {/* Iframes */}
      <div
        className='iframes-container container-outline'
        onClick={handleIframeClick}
        data-testid='practice-iframe'
      >
        <h2 className='container-text'>Iframes</h2>
        <p className='container-text'>Click here to practice Iframes</p>
      </div>

      {/* Api */}
      <div
        className='api-container container-outline'
        onClick={handleApiClick}
        data-testid='practice-api'
      >
        <h2 className='container-text'>Apis</h2>
        <p className='container-text'>Click here to practice Api requests</p>
      </div>

      {/* Dynamic text */}
      <div
        className='dyanmic-text-container container-outline'
        onClick={handleDynamicTextClick}
        data-testid='practice-dynamic-text'
      >
        <h2 className='container-text'>Dynamic Text</h2>
        <p className='container-text'>Click here to practice Dynamic Text</p>
      </div>

      {/* File Upload */}
      <div
        className='file-upload-container container-outline'
        onClick={handleFileUploadClick}
        data-testid='practice-file-upload'
      >
        <h2 className='container-text'>File Upload</h2>
        <p className='container-text'>Click here to practice Uploading files</p>
      </div>
      {/* Drag and drop */}
      <div
        className='drag-and-drop-container container-outline'
        onClick={handleDragAndDropClick}
        data-testid='practice-drag-drop'
      >
        <h2 className='container-text'>Drag and drop</h2>
        <p className='container-text'>
          Click here to practice Dragging and dropping
        </p>
      </div>
      {/* Contact Form */}
      <div
        className='contact-form-container container-outline'
        onClick={handleContactFormClick}
        data-testid='practice-contact-form'
      >
        <h2 className='container-text'>Contact Us Form</h2>
        <p className='container-text'>
          Click here to practice filling out forms
        </p>
      </div>
      {/* Mock Datalayer */}
      <div
        className='mock-data-layer-container container-outline'
        onClick={handleMockDataLayerClick}
        data-testid='practice-mock-data-layer'
      >
        <h2 className='container-text'>Mock Datalayer</h2>
        <p className='container-text'>
          Click here to practice testing a mocked version of a datalayer
        </p>
      </div>
      <div
        className='file-download-container container-outline'
        onClick={handleFileDownloadClick}
        data-testid='practice-file-download'
      >
        <h2 className='container-text'>File Download</h2>
        <p className='container-text'>
          Click here to practice testing file downloading
        </p>
      </div>
      <div
        className='clock-container container-outline'
        onClick={handleClockClick}
        data-testid='practice-clock'
      >
        <h2 className='container-text'>Time testing</h2>
        <p className='container-text'>Click here to practice testing time</p>
      </div>
    </div>
  );
}

export default Practice;
