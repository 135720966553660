import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      if (username === "test" && password === "test") {
        handleLogin(username, password);
        navigate("/");
      } else {
        setError("Invalid username or password");
        setUsername("");
        setPassword("");
      }
    } else {
      setError("Please enter a username and password");
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input
            type='text'
            placeholder='Enter Username'
            data-testid='username-textbox'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type='password'
            placeholder='Enter Password'
            data-testid='password-textbox'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <div className='error'>{error}</div>}
        <button type='submit' data-testid='login-button'>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
