import React, { useState, useRef } from "react";
import "../../css/practice/FileUpload.css";
import { useNavigate } from "react-router-dom";

const FileUpload = () => {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setValidationError(true);
    } else {
      setValidationError(false);
      alert("File successfully uploaded!");
      setSelectedFile(null);
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='file-upload'>
        <form onSubmit={handleSubmit}>
          <label htmlFor='file-input' data-testid='file-input-label'>
            Choose a file:
          </label>
          <input
            id='file-input'
            data-testid='file-input'
            type='file'
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <button type='submit'>Submit</button>
        </form>
        {validationError && (
          <div className='error-message'>Please select a file to upload.</div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
