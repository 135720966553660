import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductForm from './ProductForm';

function AddProduct(props) {
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: ''
  });

  const handleChange = (event) => {
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (product) => {
    props.addProduct(product);
    navigate('/');
  };

    // Get the ID of the next product
    const getNextProductId = () => {
      if (props.products.length === 0) {
        // If there are no products, set the ID to 1
        return 1;
      } else {
        // Otherwise, get the ID of the last product and add 1
        return props.products[props.products.length - 1].id + 1;
      }
    };

  return (
    <div>
      <h1>Add Product</h1>
      <ProductForm
        product={product}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default AddProduct;
