import React, { useState } from "react";

function Buttons() {
  const [buttonClickText, setButtonClickText] = useState("");
  const [doubleClickText, setDoubleClickText] = useState("");
  const [rightClickText, setRightClickText] = useState("");

  const handleButtonClick = () => {
    setButtonClickText("Button clicked");
  };

  const handleButtonDoubleClick = () => {
    setDoubleClickText("Button double clicked");
  };

  const handleButtonRightClick = (event) => {
    event.preventDefault();
    setRightClickText("Button right mouse clicked");
  };

  return (
    <div className='component-container'>
      <div className='button-container'>
        <button
          className='practice-button'
          data-testid='basic-click'
          onClick={handleButtonClick}
        >
          Click me
        </button>
        {buttonClickText && <p>{buttonClickText}</p>}
        <button
          className='practice-button'
          data-testid='double-click'
          onDoubleClick={handleButtonDoubleClick}
        >
          Double click me
        </button>
        {doubleClickText && <p>{doubleClickText}</p>}
        <button
          className='practice-button'
          onContextMenu={handleButtonRightClick}
          data-testid='right-click'
        >
          Right click me
        </button>
        {rightClickText && <p>{rightClickText}</p>}
      </div>
    </div>
  );
}

export default Buttons;
