import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/practice/Clock.css";

const Clock = () => {
  const [time, setTime] = useState(new Date());
  const [timer, setTimer] = useState(300); // 300 seconds = 5 minutes
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    const timeInterval = setInterval(() => setTime(new Date()), 1000);

    const timerInterval = setInterval(() => {
      const elapsedTime = Math.floor(
        (Date.now() - startTimeRef.current) / 1000
      );
      const remainingTime = 300 - elapsedTime;
      if (remainingTime > 0) {
        setTimer(remainingTime);
      } else {
        clearInterval(timerInterval);
        setMessage("YOU WON... GO SUBSCRIBE TO COMMIT QUALITY");
        setTimer(0);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
      clearInterval(timerInterval);
    };
  }, []);

  const handlePracticeClick = () => {
    navigate("/practice");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className='clock-container'>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        back to practice
      </a>
      <h2>Current time</h2>
      <h2 data-testid='clock' className='clock'>
        {time.toLocaleTimeString()}
      </h2>
      <h2>COUNTDOWN TIMER TO WIN A SPECIAL PRIZE</h2>
      <h2 data-testid='timer' className='timer'>
        {formatTime(timer)}
      </h2>
      {message && (
        <h2 data-testid='message' className='message'>
          {message}
        </h2>
      )}
    </div>
  );
};

export default Clock;
