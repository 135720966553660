import React, { useState, useLayoutEffect } from "react";
import "../../css/practice/DragAndDrop.css";
import { useNavigate } from "react-router-dom";

function DragAndDrop() {
  const [dragging, setDragging] = useState(false);
  const [inside, setInside] = useState(false);
  const navigate = useNavigate();

  //Shoot user to top of page
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePracticeClick = () => {
    navigate("/practice");
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id);
    setDragging(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setInside(true);
  };

  const handleDragLeave = () => {
    setInside(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text/plain");
    if (data === "small-box") {
      setInside(true);
    }
    setDragging(false);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='drag-and-drop'>
        <div
          className={`small-box ${dragging ? "dragging" : ""}`}
          id='small-box'
          data-testid='small-box'
          draggable='true'
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          Drag me!
        </div>
        <div
          className={`large-box ${inside ? "inside" : ""}`}
          data-testid='large-box'
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {inside ? "Success!" : "Drag the small box here."}
        </div>
      </div>
    </div>
  );
}

export default DragAndDrop;
