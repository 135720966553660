import React from "react";
import "../../css/practice/iframe.css";
import { useNavigate } from "react-router-dom";

function IFrame() {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };
  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='component-container'>
        <h2>IFrame</h2>
        <div className='iframe-container'>
          <iframe src='/' title='Products' data-testid='iframe' />
        </div>
      </div>
    </div>
  );
}

export default IFrame;
