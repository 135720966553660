import React, { useState } from "react";
import "../../css/practice/RadioButtons.css";

function RadioButtons() {
  const [radioOption, setRadioOption] = useState("");

  const handleRadioOptionChange = (event) => {
    setRadioOption(event.target.value);
  };

  return (
    <div className='component-container'>
      <div className='radio-button-container'>
        <input
          type='radio'
          name='option'
          value='option1'
          checked={radioOption === "option1"}
          onChange={handleRadioOptionChange}
          data-testid='option1'
        />
        <label>Radio button</label>
      </div>
      <div className='radio-container'>
        <input
          type='radio'
          name='option'
          value='option2'
          checked={radioOption === "option2"}
          onChange={handleRadioOptionChange}
          data-testid='option2'
        />
        <label>Radio button 2</label>
      </div>
      {radioOption && <p>{radioOption} clicked</p>}
    </div>
  );
}

export default RadioButtons;
