import React, { Fragment, useState } from "react";
import Buttons from "./Buttons";
import "../../css/Practice.css";
import "../../css/practice/Accordion.css";
import { useNavigate } from "react-router-dom";

import RadioButtons from "./RadioButtons";
import Dropdown from "./Dropdown";
import Checkbox from "./Checkbox";
import Links from "./Links";

function GeneralComponents() {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };
  return (
    <Fragment>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='buttons-container container-outline'>
        <h2 className='container-text'> Buttons</h2>
        <Buttons />
      </div>
      <div className='radio-buttons-container container-outline'>
        <h2 className='container-text'> Radio buttons</h2>
        <RadioButtons />
      </div>
      <div className='dropdown-container container-outline'>
        <h2 className='container-text'> Select an option</h2>
        <Dropdown />
      </div>
      <div className='checkbox-container container-outline'>
        <h2 className='container-text'> Checkboxes</h2>
        <Checkbox />
      </div>
      <div className='links-container container-outline'>
        <h2 className='container-text'> Links</h2>
        <Links />
      </div>
    </Fragment>
  );
}

export default GeneralComponents;
