import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/ProductForm.css";

function ProductForm({
  onSubmit,
  initialValues = { name: "", price: "", dateStocked: "" },
  isEditing,
}) {
  const [product, setProduct] = useState(initialValues);
  const [nameError, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [dateError, setDateError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const nameMinLength = 2;
  const priceMaxLength = 10;
  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const validateName = () => {
    if (product.name.trim().length < nameMinLength) {
      setNameError(`Name must be at least ${nameMinLength} characters.`);
    } else {
      setNameError("");
    }
  };

  const validatePrice = () => {
    if (
      !/^\d+(\.\d{0,2})?$/.test(product.price) ||
      product.price.length > priceMaxLength
    ) {
      setPriceError(
        `Price must not be empty and within ${priceMaxLength} digits`
      );
    } else {
      setPriceError("");
    }
  };

  const validateDate = () => {
    if (!product.dateStocked) {
      setDateError("Date must not be empty.");
    } else {
      const dateStocked = new Date(product.dateStocked);
      if (dateStocked < minDate) {
        setDateError("Date must not be older than 100 years.");
      } else if (dateStocked > maxDate) {
        setDateError("Date must not be in the future.");
      } else {
        setDateError("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateName();
    validatePrice();
    validateDate();
    setFormSubmitted(true);

    if (
      !nameError &&
      !priceError &&
      !dateError &&
      product.name &&
      product.price &&
      product.dateStocked
    ) {
      onSubmit(product);
    }
  };

  const handleNameInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;

    // Only allow numbers and decimal point
    if (/^[0-9.]*$/.test(value)) {
      // Split the value into integer and decimal parts
      const parts = value.split(".");

      // Limit the decimal part to 2 digits
      if (parts.length === 2 && parts[1].length > 2) {
        // If the decimal part is longer than 2 digits, truncate it
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: `${parts[0]}.${parts[1].slice(0, 2)}`,
        }));
      } else {
        // Otherwise, update the state with the new value
        setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
      }
    }
  };

  const handleNameBlur = () => {
    validateName();
  };

  const handlePriceBlur = () => {
    validatePrice();
  };

  const handleDateBlur = () => {
    validateDate();
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='form-group'>
        <label htmlFor='name'>Name</label>
        {nameError && <div className='error-message'>{nameError}</div>}
        <input
          type='text'
          placeholder='Enter a product name'
          data-testid='product-textbox'
          className={`form-control ${nameError ? "is-invalid" : ""}`}
          id='name'
          name='name'
          value={product.name}
          onChange={handleNameInputChange}
          onBlur={handleNameBlur}
          required
        />
      </div>
      <div className='form-group'>
        <label htmlFor='price'>Price</label>
        {priceError && <div className='error-message'>{priceError}</div>}
        <input
          type='text'
          placeholder='Enter a price'
          data-testid='price-textbox'
          className={`form-control ${priceError ? "is-invalid" : ""}`}
          id='price'
          name='price'
          value={product.price}
          onChange={handleNumberInputChange}
          onBlur={handlePriceBlur}
          required
        />
      </div>
      <div className='form-group'>
        <label htmlFor='dateStocked'>Date Stocked</label>
        {dateError && <div className='error-message'>{dateError}</div>}
        <input
          type='date'
          className={`form-control ${dateError ? "is-invalid" : ""}`}
          data-testid='date-stocked'
          id='dateStocked'
          name='dateStocked'
          value={product.dateStocked}
          onChange={handleNameInputChange}
          onBlur={handleDateBlur}
          required
        />
      </div>
      {formSubmitted &&
        (!product.name || !product.price || !product.dateStocked) && (
          <div
            className='error-message'
            data-testid='fillin-all-fields-validation'
          >
            Please fill in all fields
          </div>
        )}

      {(nameError || priceError || dateError) && (
        <div className='error' data-testid='all-fields-validation'>
          Errors must be resolved before submitting
        </div>
      )}
      <div className='btn-group'>
        <button
          type='submit'
          className='btn btn-primary button'
          data-testid='submit-form'
        >
          {isEditing ? "Update" : "Submit"}
        </button>
        <Link to='/' className='btn btn-secondary' data-testid='cancel-button'>
          cancel
        </Link>
      </div>
    </form>
  );
}

export default ProductForm;
