import React, { useState } from "react";
import { Link } from "react-router-dom";
import Banner from "./Banner"; // Import the Banner component
import "../css/ProductList.css";

const ProductList = ({ products, deleteProduct, isLoggedIn, editProduct }) => {
  const [numProducts, setNumProducts] = useState(10);
  const [filterText, setFilterText] = useState("");

  const handleEdit = (index) => {
    const productToEdit = products[index];
    editProduct(productToEdit);
  };

  const handleDelete = (id) => {
    deleteProduct(id);
    setFilteredProducts(
      filteredProducts.filter((product) => product.id !== id)
    );
  };

  const showMore = () => {
    setNumProducts(numProducts + 10);
  };

  const handleFilter = () => {
    // filter the products based on the filterText
    setFilteredProducts(
      products.filter((product) =>
        product.name.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  };

  const handleClear = () => {
    setFilterText("");
    setFilteredProducts(products);
  };

  const [filteredProducts, setFilteredProducts] = useState(products);

  return (
    <div className='product-list-container'>
      <head>
        <meta name='viewport' content='width=device-width, initial-scale=0.7' />
      </head>
      {/* Render the Banner component */}
      <Banner />
      <div className='filter-container'>
        <input
          type='text'
          placeholder='Filter by product name'
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className='filter-textbox'
        />
        <button
          className='filter-button'
          onClick={handleFilter}
          data-testid='filter-button'
        >
          Filter
        </button>
        <button
          className='filter-button'
          onClick={handleClear}
          data-testid='reset-filter-button'
        >
          Reset
        </button>
      </div>

      {/* <h2>Product List</h2> */}
      {filteredProducts.length > 0 ? (
        <table className='product-list-table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Price</th>
              <th>Date Stocked</th>
              {isLoggedIn && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {[...filteredProducts]
              .reverse()
              .slice(0, numProducts)
              .map((product, index) => (
                <tr key={index} data-testid={`product-row-${product.id}`}>
                  <td data-testid='id'>{product.id}</td>
                  <td data-testid='name'>{product.name}</td>
                  <td data-testid='price'>{product.price}</td>
                  <td data-testid='dateStocked'>{product.dateStocked}</td>
                  {isLoggedIn && (
                    <td className='product-list-actions'>
                      <Link
                        to={`/edit-product/${product.id}`}
                        onClick={() => handleEdit(index)}
                        className='edit-button'
                        data-testid='edit-button'
                      >
                        Edit
                      </Link>
                      {" | "}
                      <Link
                        onClick={() => handleDelete(product.id)}
                        className='delete-button'
                        data-testid='delete-button'
                      >
                        Delete
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className='add-product-message'>No products found</p>
      )}
      {filteredProducts.length > numProducts && (
        <p className='show-more-link'>
          <button
            onClick={showMore}
            className='edit-button'
            data-testid='show-more-button'
          >
            Show More
          </button>
        </p>
      )}
      <p className='add-product-link'>
        <Link
          to='/add-product'
          className='add-product-button'
          data-testid='add-a-product-button'
        >
          Add a Product
        </Link>
      </p>
    </div>
  );
};

export default ProductList;
