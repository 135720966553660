import React, { useState, useRef } from "react";
import "../../css/practice/FileUpload.css";
import { useNavigate } from "react-router-dom";

const DownloadFile = () => {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };
  const handleDownload = () => {
    // Create a dummy text file content
    const dummyText = "This is a dummy text file.";

    // Create a Blob object with the file content
    const blob = new Blob([dummyText], { type: "text/plain" });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "dummy_file.txt"; // Set the file name

    // Append the anchor element to the document body
    document.body.appendChild(a);

    // Programmatically trigger a click event on the anchor element
    a.click();

    // Remove the anchor element from the document body
    document.body.removeChild(a);

    // Revoke the URL to release memory
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <button onClick={handleDownload}>Download File</button>
    </div>
  );
};

export default DownloadFile;
