import React, { useState } from "react";
import "../css/Account.css";

function Account() {
  const [name, setName] = useState("Commit Quality");
  const [youtube, setYoutube] = useState("CommitQuality");
  const [savedName, setSavedName] = useState(name);
  const [savedYoutube, setSavedYoutube] = useState(youtube);
  const [detailsVisible, setDetailsVisible] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleYoutubeChange = (event) => {
    setYoutube(event.target.value);
  };

  const handleSave = () => {
    setSavedName(name);
    setSavedYoutube(youtube);
    alert(`Name: ${name}\nYoutube: ${youtube}`);
  };

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div className='container my-4'>
      <h1 className='title'>My Account</h1>
      <div className='row my-4'>
        <div className='col-md-6' data-testid='my-details-container'>
          <h2 className='details-heading'>My Details</h2>
          <ul className='details-list'>
            <li>
              <span className='details-label' data-testid='saved-name-info'>
                Name:
              </span>{" "}
              {savedName}
            </li>
            <li>
              <span
                className='details-label'
                data-testid='saved-youtube-name-info'
              >
                Youtube Name:
              </span>{" "}
              {savedYoutube}
            </li>
            <li>
              <span
                className='details-label'
                data-testid='saved-youtube-channel-info'
              >
                <a href='https://www.youtube.com/@commitquality'>
                  Youtube Link
                </a>{" "}
              </span>
            </li>
          </ul>
        </div>
        <div className='col-md-6'>
          <div
            className='update-details-container'
            data-testid='update-detials-container'
          >
            <h2 className='update-details-heading'>
              Update Details
              <span onClick={toggleDetails} className='toggle-details-button'>
                {detailsVisible ? "-" : "+"}
              </span>
            </h2>
            {detailsVisible && (
              <div>
                <div className='form-group'>
                  <label htmlFor='name' className='update-details-label'>
                    Name
                  </label>
                  <input
                    type='text'
                    placeholder='Enter new name'
                    className='form-control update-details-input'
                    id='name'
                    value={name}
                    onChange={handleNameChange}
                    data-testid='name-textbox'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='youtube' className='update-details-label'>
                    Youtube
                  </label>
                  <input
                    type='text'
                    className='form-control update-details-input'
                    id='youtube'
                    value={youtube}
                    onChange={handleYoutubeChange}
                    placeholder='Enter Youtube Channel'
                    data-testid='yotuube-channel-textbox'
                  />
                </div>
                <button
                  type='submit'
                  className='btn btn-primary update-details-save-button'
                  onClick={handleSave}
                  data-testid='submit-data'
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
