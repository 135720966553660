import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import ProductList from "./components/ProductList";
import AddProduct from "./components/AddProduct";
import Login from "./components/Login";
import Account from "./components/Account";
import EditProduct from "./components/EditProduct";
import Practice from "./components/Practice";
import Buttons from "./components/practice/Buttons";
import RadioButtons from "./components/practice/RadioButtons";
import Checkbox from "./components/practice/Checkbox";
import Dropdown from "./components/practice/Dropdown";
import Api from "./components/practice/Api";
import IFrame from "./components/practice/IFrame";
import Links from "./components/practice/Links";
import DynamicText from "./components/practice/DynamicText";
import FileUpload from "./components/practice/FileUpload";
import DragAndDrop from "./components/practice/DragAndDrop";
import ContactForm from "./components/practice/ContactForm";
import Accordion from "./components/practice/Accordion";
import GeneralComponents from "./components/practice/GeneralComponents";
import DataLayer from "./components/practice/DataLayer";
import RandomPopup from "./components/practice/RandomPopup";
import DownloadFile from "./components/practice/DownloadFile";
import Clock from "./components/practice/Clock";

function App() {
  const [products, setProducts] = useState([
    { id: 1, name: "Product 1", price: 10.0, dateStocked: "2021-01-01" },
    { id: 2, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
    { id: 3, name: "Product 1", price: 10.0, dateStocked: "2021-01-01" },
    { id: 4, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
    { id: 5, name: "Product 1", price: 10.0, dateStocked: "2021-01-01" },
    { id: 6, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
    { id: 7, name: "Product 1", price: 10.0, dateStocked: "2021-01-01" },
    { id: 8, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
    { id: 9, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
    { id: 10, name: "Product 1", price: 10.0, dateStocked: "2021-01-01" },
    { id: 11, name: "Product 2", price: 15.0, dateStocked: "2021-02-01" },
  ]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedIsLoggedIn === "true");
  }, []);

  const addProduct = (product) => {
    setProducts([...products, product]);
  };

  const editProduct = (product) => {
    const index = products.findIndex((p) => p.id === product.id);
    if (index !== -1) {
      const newProducts = [...products];
      newProducts[index] = product;
      setProducts(newProducts);
    }
  };

  const deleteProduct = (id) => {
    setProducts(products.filter((p) => p.id !== id));
  };

  const handleLogin = (username, password) => {
    if (username === "test" && password === "test") {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  return (
    <Router>
      <div className='App'>
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        <div className='container'>
          <Routes>
            <Route
              exact
              path='/'
              element={
                <ProductList
                  products={products}
                  deleteProduct={deleteProduct}
                  isLoggedIn={isLoggedIn}
                  editProduct={editProduct}
                />
              }
            />
            <Route
              path='/add-product'
              element={
                <AddProduct
                  addProduct={(product) => {
                    if (products.length > 0)
                      addProduct({
                        ...product,
                        id: products[products.length - 1].id + 1,
                      });
                    else addProduct({ ...product, id: 1 });
                  }}
                />
              }
            />
            <Route
              path='/login'
              element={<Login handleLogin={handleLogin} />}
            />
            <Route
              path='/account'
              element={<Account isLoggedIn={isLoggedIn} />}
            />
            <Route
              path='/edit-product/:id'
              element={
                <EditProduct products={products} editProduct={editProduct} />
              }
            />
            <Route path='/practice' element={<Practice />} />
            <Route path='/practice-buttons' element={<Buttons />} />
            <Route path='/practice-radio-buttons' element={<RadioButtons />} />
            <Route path='/practice-checkbox' element={<Checkbox />} />
            <Route path='/practice-api' element={<Api />} />
            <Route path='/practice-dropdown' element={<Dropdown />} />
            <Route path='/practice-iframe' element={<IFrame />} />
            <Route path='/practice-link' element={<Links />} />
            <Route path='/practice-dyanmic-text' element={<DynamicText />} />
            <Route path='/practice-file-upload' element={<FileUpload />} />
            <Route path='/practice-drag-and-drop' element={<DragAndDrop />} />
            <Route path='/practice-contact-form' element={<ContactForm />} />
            <Route path='/practice-accordions' element={<Accordion />} />
            <Route path='/practice-random-popup' element={<RandomPopup />} />
            <Route
              path='/practice-general-components'
              element={<GeneralComponents />}
            />
            <Route path='/practice-mock-data-layer' element={<DataLayer />} />
            <Route path='/practice-file-download' element={<DownloadFile />} />
            <Route path='/practice-clock' element={<Clock />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
