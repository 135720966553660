import React from "react";

function Dropdown({ value, onChange }) {
  return (
    <div className='dropdowns' data-testid='dropdown'>
      <select value={value} onChange={onChange}>
        <option value=''>Select an option</option>
        <option value='option1'>Option 1</option>
        <option value='option2'>Option 2</option>
        <option value='option3'>Option 3</option>
      </select>
    </div>
  );
}

export default Dropdown;
