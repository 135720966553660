import React from "react";
import { Fragment } from "react";
import "../../css/practice/Links.css";

function Links() {
  return (
    <Fragment>
      <div className='links-container'>
        <a
          href='https://www.youtube.com/@commitquality'
          data-testid='link-same-tab'
        >
          My Youtube
        </a>
      </div>
      <div className='links-container'>
        <a
          href='https://www.youtube.com/@commitquality'
          target='_blank'
          rel='noreferrer'
          data-testid='link-newtab'
        >
          Open my youtube in a new tab
        </a>
      </div>
      <div className='links-container'>
        <a
          href='/practice'
          target='_blank'
          rel='noreferrer'
          data-testid='link-newtab-practice'
        >
          Go to practice page
        </a>
      </div>
    </Fragment>
  );
}

export default Links;
