// MockDataLayer.js

const mockDataLayer = {
  pageName: "MockDataLayer",
  subscribedToCommitQuality: true,
  favouriteYoutubeChannel: "CommitQuality",
  // ... other properties you want to include
};

export default mockDataLayer;
