import "../css/navbar.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

function Navbar(props) {
  const { isLoggedIn, handleLogout } = props;
  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef(null);
  const location = useLocation();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleOutsideClick = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <nav className='navbar' ref={navRef}>
      <div className='nav-toggle' onClick={handleMenuClick}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`nav-links ${showMenu ? "show" : ""}`}>
        <NavLink
          className='nav-link'
          activeClassName='selected'
          exact
          to='/'
          data-testid='navbar-products'
        >
          Products
        </NavLink>
        <NavLink
          className='nav-link'
          activeClassName='selected'
          exact
          to='/add-product'
          data-testid='navbar-addproduct'
        >
          Add Product
        </NavLink>
        <NavLink
          className='nav-link'
          activeClassName='selected'
          exact
          to='/practice'
          data-testid='navbar-practice'
        >
          Practice
        </NavLink>
        <a
          className='nav-link'
          href='https://www.youtube.com/@commitquality'
          target='_blank'
          data-testid='navbar-learn'
        >
          Learn
        </a>
        {isLoggedIn ? (
          <>
            <NavLink
              className='nav-link account-links'
              activeClassName='selected'
              exact
              to='/account'
              data-testid='navbar-account'
            >
              My Account
            </NavLink>
            <Link
              className='logout-link'
              to='/'
              onClick={handleLogout}
              data-testid='navbar-logout'
            >
              Logout
            </Link>
          </>
        ) : (
          <NavLink
            className='nav-link'
            activeClassName='selected'
            exact
            to='/login'
            data-testid='navbar-login'
          >
            Login
          </NavLink>
        )}
        {showMenu && (
          <div className='close-btn' onClick={handleMenuClick}>
            <span>&times;</span>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
