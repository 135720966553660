// Banner.js
import React from "react";
import "../css/Banner.css";

const Banner = () => {
  return (
    <div className='banner-container' data-testid='banner-container'>
      <p className='banner-message' data-testid='banner-text'>
        ADVERTISE YOUR PRODUCT / SERVICE HERE: Contact me on X{" "}
        <a
          href='https://x.com/commitquality'
          target='_blank'
          data-testid='banner-advert-link'
        >
          @CommitQuality
        </a>
      </p>
    </div>
  );
};

export default Banner;
