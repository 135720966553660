import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function DynamicText() {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };
  const [visibleText, setVisibleText] = useState("Always visible");

  const handleVisibleTextChange = () => {
    setVisibleText("loading");
    setTimeout(() => {
      setVisibleText("I am visible after 5 seconds");
    }, 5000);
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='component-container'>
        <h2>Dynamic Text</h2>
        <div className='dynamic-data-container'>
          <h2> Dynamic fields</h2>
          <button
            onClick={handleVisibleTextChange}
            data-testid='dynamic-button1'
          >
            {visibleText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DynamicText;
