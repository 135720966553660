import React, { useState } from "react";
import "../../css/practice/Checkbox.css";

function Checkbox() {
  const [checkedState, setCheckedState] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState({
      ...checkedState,
      [name]: checked,
    });
  };

  return (
    <div>
      <div className='checkbox-container'>
        <input
          type='checkbox'
          name='checkbox1'
          data-testid='checkbox1'
          checked={checkedState.checkbox1}
          onChange={handleCheckboxChange}
        />
        <label data-testid='checkbox1-label'>Checkbox 1</label>
        {checkedState.checkbox1 && <p>Checkbox 1 checked</p>}
      </div>
      <div className='checkbox-container'>
        <input
          type='checkbox'
          name='checkbox2'
          data-testid='checkbox2'
          checked={checkedState.checkbox2}
          onChange={handleCheckboxChange}
        />
        <label data-testid='checkbox1-label'>Checkbox 2</label>
        {checkedState.checkbox2 && <p>Checkbox 2 checked</p>}
      </div>
      <div className='checkbox-container'>
        <input
          type='checkbox'
          name='checkbox3'
          data-testid='checkbox3'
          checked={checkedState.checkbox3}
          onChange={handleCheckboxChange}
        />
        <label data-testid='checkbox1-label'>Checkbox 3</label>
        {checkedState.checkbox3 && <p>Checkbox 3 checked</p>}
      </div>
    </div>
  );
}

export default Checkbox;
