import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../css/practice/ContactForm.css";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    queryType: "",
    dob: null,
    practice: false,
  });
  const [dateError, setDateError] = useState("");

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    // Name validation
    if (!formData.name) {
      formIsValid = false;
      errors.name = "Name is required.";
    }

    // Email validation
    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      errors.email = "Email is invalid.";
    }

    // Query Type validation
    if (!formData.queryType) {
      formIsValid = false;
      errors.queryType = "Query Type is required.";
    }

    // DOB validation
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth is required.";
    }

    // Practice website validation
    if (!formData.practice) {
      formIsValid = false;
      errors.practice =
        "Please check the box to confirm you're aware that this is a practice website.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleValidation()) {
      setSuccessMessage("Thanks for contacting us, we will never respond!");
      setFormData({
        name: "",
        email: "",
        queryType: "",
        dob: null,
        practice: false,
      });
      setErrors({});
      setDateError("");
      setFormSubmitted(true);
    } else {
      setSuccessMessage("");
    }
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    const errorsCopy = { ...errors };
    switch (name) {
      case "name":
        errorsCopy.name = formData.name ? "" : "Name is required.";
        break;
      case "email":
        errorsCopy.email = formData.email
          ? /\S+@\S+\.\S+/.test(formData.email)
            ? ""
            : "Email is invalid."
          : "Email is required.";
        break;
      case "queryType":
        errorsCopy.queryType = formData.queryType
          ? ""
          : "Query Type is required.";
        break;
      case "dob":
        errorsCopy.dob = formData.dob ? "" : "Date of Birth is required.";
        break;
      case "practice":
        errorsCopy.practice = formData.practice
          ? ""
          : "Please check the box to confirm you're aware that this is a practice website.";
        break;
      default:
        break;
    }
    setErrors(errorsCopy);
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      {successMessage && (
        <div className='success-message'>{successMessage}</div>
      )}
      <div className='contact-form'>
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit} noValidate>
          <div className='form-group'>
            <label htmlFor='name'>Name:</label>
            <input
              type='text'
              id='name'
              name='name'
              data-testid='name'
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {errors.name && <div className='error'>{errors.name}</div>}
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              data-testid='email'
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {errors.email && <div className='error'>{errors.email}</div>}
          </div>
          <div className='form-group'>
            <label htmlFor='query-type'>Query Type:</label>
            <select
              id='query-type'
              name='queryType'
              data-testid='query-type'
              value={formData.queryType}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            >
              <option value=''>Select an option</option>
              <option value='General'>General</option>
              <option value='Technical'>Technical</option>
              <option value='Billing'>Billing</option>
            </select>
            {errors.queryType && (
              <div className='error'>{errors.queryType}</div>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='dob'>Date of Birth:</label>
            <input
              type='date'
              id='dob'
              name='dob'
              data-testid='dob'
              value={formData.dob || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              max={new Date().toISOString().split("T")[0]}
              required
            />

            {errors.dob && <div className='error'>{errors.dob}</div>}
            {dateError && <div className='error'>{dateError}</div>}
          </div>
          <div className='form-group'>
            <label>
              <input
                type='checkbox'
                name='practice'
                data-testid='practice-checkbox'
                checked={formData.practice}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              I am aware that this is a practice website
            </label>
            {errors.practice && <div className='error'>{errors.practice}</div>}
          </div>
          <button type='submit' data-testid='submit-button'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default ContactForm;
