import React, { useEffect, useState } from "react";
import "../../css/Overlay.css"; // Assuming Overlay.css is located in the 'css' directory

const Overlay = ({ onClose }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const randomTime = Math.floor(Math.random() * (6000 - 2000 + 1)) + 2000; // Random time between 2 to 6 seconds
    const timeout = setTimeout(() => {
      setShowOverlay(true);
    }, randomTime);

    return () => clearTimeout(timeout);
  }, []); // Run only once on component mount

  const handleCloseButtonClick = () => {
    onClose(); // Close the overlay
  };

  if (!showOverlay) {
    return null; // Don't render anything until the random time has elapsed
  }

  return (
    <div className='overlay'>
      <div className='overlay-content'>
        <p>Random Popup</p>
        <button onClick={handleCloseButtonClick}>Close</button>
      </div>
    </div>
  );
};

export default Overlay;
