import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Api() {
  const [statusCode, setStatusCode] = useState(null);
  const [responseBody, setResponseBody] = useState(null);
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate("/practice");
  };

  const handleApiRequest = async () => {
    try {
      const response = await fetch(
        "https://jsonplaceholder.typicode.com/todos/1"
      );
      setStatusCode(response.status);
      const data = await response.json();
      setResponseBody(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <a
        className='back-link'
        data-testid='back-link'
        onClick={handlePracticeClick}
      >
        {" "}
        back to practice
      </a>
      <div className='component-container'>
        <h2>API calls</h2>
        <div className='api-container'>
          <button data-testid='get-button' onClick={handleApiRequest}>
            Make API Request(GET)
          </button>
          {statusCode && <p>Status Code: {statusCode}</p>}
          {responseBody && <pre>{JSON.stringify(responseBody, null, 2)}</pre>}
        </div>
      </div>
    </div>
  );
}

export default Api;
