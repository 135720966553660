import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import ProductForm from './ProductForm';

function EditProduct({ products, editProduct }) {
  const navigate = useNavigate();

  const { id } = useParams();
  const product = products.find((p) => p.id === parseInt(id));

  const handleSubmit = (updatedProduct) => {
    editProduct({ ...product, ...updatedProduct });
    navigate('/');

  };

  return (
    <div>
      <h2>Edit Product</h2>
      <ProductForm onSubmit={handleSubmit} initialValues={product} isEditing={true} />
    </div>
  );
}

export default EditProduct;
