// YourComponent.js

import React, { useEffect } from "react";
import mockDataLayer from "../MockDataLayer"; // Adjust the path accordingly

function YourComponent() {
  useEffect(() => {
    // Create a JSON string representation of the mockDataLayer
    const mockDataLayerString = JSON.stringify(mockDataLayer);

    // Create a script element and set its content to the mockDataLayer JSON
    const script = document.createElement("script");
    script.innerHTML = `var dataLayer = ${mockDataLayerString};`;

    // Append the script element to the head of the document
    document.head.appendChild(script);

    // Clean up: remove the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <p>
        In the script tag in dev tools you can inspect the mocked data layer
      </p>
    </div>
  );
}

export default YourComponent;
